<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  //required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Profile History",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 /*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Profiles",
          href: "/Shop/profiles"
        },
        {
          text: "Profile",
          active: true
        }
      ],
      workorderFields:['id_workorder', 'dt_creation', 'de_status'],

      submitted:    false,
      validToken:   true,
      validAccess:  true,
      user:         {},
      workorder:    {},
      workorders:   [],
      filter:       null,
      filterOn:     [],
    };
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    //this.getSites();
    
    if( this.$route.params.id ){
      this.getWorkordersByVIN();
    }
  },
  methods: {
    async getWorkordersByVIN(){
      Swal.fire({
        title: "Loading workorders history.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/workorders.php',
        {
          params: {
            request: 'getWorkordersByVIN',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.workorders = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    async getWorkordersByID(item){
      Swal.fire({
        title: "Loading workorder data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/workorders.php',
        {
          params: {
            request: 'getWorkorderByID',
            id: item.id_workorder,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.workorder = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    async getSites(){
      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSites',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    /*
    getLabor(){
      Swal.fire({
        title: "Loading labor data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/labors.php',
        {
          params: {
            request: 'getLaborsByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.labor = response.data;

            this.form.id_site         = app.labor.id_site;
            this.form.de_labor        = app.labor.de_labor;
            this.form.num_laborPrice  = app.labor.num_laborPrice;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },
    */

    // eslint-disable-next-line no-unused-vars
    saveCustomer(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_labor = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving labor.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/labors.php', {
          request: 'saveLabor',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "Your labor is correct "+action+"!", "success");
            app.$router.push({ name: 'labors' });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },


    onFiltered(filteredItems) {
      //let app   = this;
      let rows  = filteredItems.length;

      //Marking on green the spare parts or services of the workorder
      for( let i=0; i<rows; i++){
        if(filteredItems[i].selected){
          //app.$refs['selectableTable'].selectRow(i);
          this.tbodyRowClass(filteredItems[i]);
        }
      }
    },

    rowClicked(item) {
      this.getWorkorderByID(item.id_workorder);
    },

    tbodyRowClass(item) {
      /* Style the row as needed */
      if(item.selected){
        //return ["b-table-row-selected", "table-primary", "cursor-pointer"]
        return ["b-table-row-selected", "table-active"];
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveCustomer">
              <div class="row">
                <div class="col-sm-4 col-lg-4">
                  <div class="form-group">
                    <h5 class="card-title">Workorders History</h5>
                    
                    <div class="form-group">
                      <b-form-group
                        label="Filter"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="left"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                          ></b-form-input>

                          <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <b-table
                      sticky-header
                      small
                      :items="workorders"
                      :fields="workorderFields"
                      responsive="sm"
                      selectable
                      select-mode="single"
                      ref="selectableTable"
                      @row-clicked="getWorkordersByID"
                      style="max-height: 250px !important;"
                      @filtered="onFiltered"
                      :filter="filter"
                      :tbody-tr-class="tbodyRowClass"
                    >
                    </b-table>
                  </div>
                </div>

                <div class="col-sm-8 col-lg-8 pt-2 pl-5">
                  <div v-if="workorder.wo" class="row">
                    <div class="col-sm-12"><h5>{{workorder.wo.nm_make}} {{workorder.wo.nm_model}} {{workorder.wo.de_color}} {{workorder.wo.num_year}}</h5><br></div>
                    <div class="col-sm-6">
                      <!-- LABORS -->
                      <h5>Labors</h5>
                      <ol>
                        <li v-for="(labor, index) in workorder.ct.de_spareParts" :key="index">{{labor.labor}}</li>
                      </ol>
                      <p><b>Notes:</b> {{workorder.dx.de_notes}}</p>
                      <p><b>Diagnostic:</b> {{workorder.dx.de_diagnostic}}</p>
                      <p><b>Observations:</b> {{workorder.rv.de_observations}}</p>
                      <p><b>User signature:</b><br><img :src="workorder.dx.url_signatureCustomer"></p>
                    </div>

                    <div class="col-sm-6">
                      <!-- OTHERS -->
                      <div class="col-sm-12">
                        <h5>Other</h5>
                        <!-- SPARE PARTS PROVISIONING -->
                        <p class="m-0">
                          <span v-if="workorder.dx.sn_provideSpareParts == 1"><b>Customer have provided spare parts.</b></span>
                          <span v-else><b>Customer didn't provide spare parts.</b></span>
                        </p>
                        
                        <!-- USED SPARE PARTS ACCEPTANCE -->
                        <p class="m-0">
                          <span v-if="workorder.dx.sn_acceptSpareParts == 1"><b>Customer have approved the use of used spare parts.</b></span>
                          <span v-else><b>Customer have not approved the use of used spare parts.</b></span>
                        </p>

                        <!-- GUARANTEE -->
                        <p v-if="workorder.ct.sn_guarantee == 0" class="m-0">
                          <b>Guarantee:</b>
                          <span v-if="workorder.ct.num_guaranteeDays > 0">{{workorder.ct.num_guaranteeDays}} day(s).</span>
                          <span v-if="workorder.ct.num_guaranteeMilesKM > 0">{{workorder.ct.num_guaranteeMilesKM}} kms/miles.</span>
                        </p>
                        <p v-else class="m-0"><b>No guarantee.</b></p>

                        <!-- USE OF INFORMATION ACCEPTANCE -->
                        <p class="m-0">
                          <span v-if="workorder.dx.sn_useOfData == 1"><b>Customer have approved the use of their information for marketing or advertising purposes.</b></span>
                          <span v-else><b>Customer have not approved the use of their information for marketing or advertising purposes.</b></span>
                        </p>

                        <!-- USE OF INFORMATION FOR MARKETING ACCEPTANCE -->
                        <p class="m-0">
                          <span v-if="workorder.dx.sn_useOfPublicity == 1"><b>Customer agreed that the service provider may send you advertising about goods and services.</b></span>
                          <span v-else><b>Customer is not agree that the service provider may send you advertising about goods and services.</b></span>
                        </p>
                      </div>

                      <!-- COST -->
                      <div class="col-sm-12 mt-5">
                        <h5>Cost</h5>
                        <div class="row">
                          <div class="col-sm-4"><b>Spare Parts: </b></div><div class="col-sm-8">${{workorder.ct.num_spareParts.toFixed(2)}}</div>
                          <div class="col-sm-4"><b>Workforce: </b></div><div class="col-sm-8">${{workorder.ct.num_workForce.toFixed(2)}}</div>
                          <div class="col-sm-4"><b>Subtotal: </b></div><div class="col-sm-8">${{workorder.ct.num_subTotal.toFixed(2)}}</div>
                          <div class="col-sm-4"><b>Taxes: </b></div><div class="col-sm-8">${{workorder.ct.num_taxes.toFixed(2)}}</div>
                          <div class="col-sm-4 text-info"><b>Total: </b></div><div class="col-sm-8"><b>${{workorder.ct.num_totalPrice.toFixed(2)}}</b></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--
              <div class="row">
                <div class="col-lg-12 mt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE LABOR</button>
                    <router-link to="/shop/labors" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
              -->
            </form>
          </div>
          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>